import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import PropTypes from 'prop-types'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const WYSIWYG = React.forwardRef((props, ref) => {
  const { defaultValue, name } = props

  const styles = {
    height: '25vh'
  }

  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(defaultValue || '').contentBlocks
      )
    )
  )
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const onContentStateChange = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()))
  }

  return (
    <Editor
      ref={ref}
      editorStyle={styles}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      onContentStateChange={onContentStateChange}
      name={name}
    />
  )
})

WYSIWYG.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string
}

export default WYSIWYG
