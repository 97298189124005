import React from 'react'
import * as styles from './styles'
import MessageText from './MessageText'
import MessageImage from './MessageImage'
import MessageVideo from './MessageVideo'
import MessageFile from './MessageFile'

const MessageBubble = ({ text, contentType, contentMedia, authorType }) => {

  const renderContent = () => {
    switch (contentType) {
      case 'text':
        return <MessageText text={text} />
      case 'image':
        return <MessageImage text={text} contentMedia={contentMedia} />
      case 'video':
        return <MessageVideo text={text} contentMedia={contentMedia} />
      case 'file':
        return <MessageFile text={text} contentMedia={contentMedia} />
      default:
        return <p>Unsupported message type</p>
    }
  }

  return <styles.Container authorType={authorType}>{renderContent()}</styles.Container>
}

export default MessageBubble