import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'

import Avatar from 'app/components/generic/Avatar'
import EmptyDataIcon from 'app/components/generic/EmptyDataIcon'
import PillList from 'app/components/generic/PillList'
import { navigationPaths } from 'app/config'
import {
  selectChatMessagesData,
  selectChatMessagesHeaderData
} from 'app/store/selectors/communicationEntity/chat'

import * as styles from './styles'

import MessageComponent from '../MessageComponent'

const MessageList = () => {
  const chatMessagesData = useSelector(selectChatMessagesData)
  const chatMessagesHeaderData = useSelector(selectChatMessagesHeaderData)
  const chatData = chatMessagesData?.data
  const chatHeaderData = chatMessagesHeaderData?.chatMessagesHeaderData
  const sortedData = chatData?.sort((a, b) => a?.created - b?.created)

  return (
    <styles.MessageListContainer>
      <styles.HeaderContainer>
        {(chatHeaderData?.url || chatHeaderData?.name) && (
          <Avatar
            thumbnailUrl={chatHeaderData?.url}
            name={chatHeaderData?.name}
            avatarImageStyles={styles.avatarImageStyles}
            avatarTextStyles={styles.avatarTextStyles}
          />
        )}
        <styles.HeaderDetails>
          <styles.HeaderText>{chatHeaderData?.name}</styles.HeaderText>
          <PillList
            data={chatHeaderData?.chat_labels}
            pillStyles={styles.PillStyles}
            source={'message_list_header'}
            truncationLength={10}
          />
          {chatHeaderData?.parent_number && (
            <styles.HeaderDescription>
              {`parent's phone no. - `}
              <styles.PhoneLink
                href={`${navigationPaths.parentdashboard}?parent=${chatHeaderData?.parent_number}`}>
                {chatHeaderData?.parent_number}
              </styles.PhoneLink>
            </styles.HeaderDescription>
          )}
        </styles.HeaderDetails>
      </styles.HeaderContainer>

      <styles.BodyContainer>
        {sortedData.length > 0 ? (
          sortedData.map((item, index) => (
            <MessageComponent
              timestamp={dayjs(item?.created).format(
                'hh:mm A • ddd • DD MMM YYYY'
              )}
              authorType={item?.author_type}
              authorIcon={item?.author_icon}
              authorName={item?.author_name}
              status={item?.status}
              contentText={item?.content?.body}
              contentType={item?.content?.type}
              contentMedia={item?.content?.media?.value}
            />
          ))
        ) : (
          <EmptyDataIcon />
        )}
      </styles.BodyContainer>
    </styles.MessageListContainer>
  )
}

export default MessageList
