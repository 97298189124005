import dayjs from 'dayjs'

export const formatTimestamp = (timestamp) => {
  const date = dayjs(timestamp);
  const now = dayjs();

  if (date.isSame(now, 'day')) {
    return date.format('hh:mm a') // 12-hour format
  } else {
    return date.format('DD/MM/YY') // MM/DD/YY format
  }
}

export const sortDataByLastDate = (data) => {
  return data?.sort((a, b) => {
    const dateA = new Date(a?.last_message?.created)
    const dateB = new Date(b?.last_message?.created)
    return dateB - dateA
  })
}

export const getChatHeaderData = (data) => {
  const { teacher_display_meta, parent_search_meta } = data?.details || {}
  const { student_level } = parent_search_meta || {}
  const { chat_thumbnail, chat_title, chat_labels, chat_thumbnail_labels } = teacher_display_meta || {}
  const milestone = chat_thumbnail_labels?.milestone

  const newChatLabels = {
    ...chat_labels
  }

  if (student_level !== 'BEG' && milestone != 0) {
    newChatLabels.journey = `${student_level} | ${milestone}`
  }

  newChatLabels.subject = data?.journey_subject_name

  const dataObject = {
    url: chat_thumbnail,
    name: chat_title,
    chat_labels: newChatLabels,
    parent_number: parent_search_meta?.parent_phone_number,
  }

  return dataObject
}
