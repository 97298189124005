import React from 'react'
import TeacherList from './components/TeacherList'
import StudentList from './components/StudentList'
import MessageList from './components/MessageList'
import * as styles from './styles'
import { useDispatch } from 'react-redux'
import { fetchTeacherListData } from 'app/store/actions/communicationEntity'
import constants from 'app/constants'

const Chat = () => {

  const dispatch = useDispatch()
  //get centre from local storage
  const centre = JSON.parse(localStorage.getItem('SELECTED_CENTRE'))

  React.useEffect(() => {
    dispatch(fetchTeacherListData({
      queries: {
        centre_uuid: centre?.value,
        limit: 1000
      }
    }, false, false, constants.TEACHER_LIST))
  }, [])

  return (
    <styles.ListContainer>
      <TeacherList />
      <StudentList />
      <MessageList />
    </styles.ListContainer>
  )
}

export default Chat