import React from 'react'
import * as styles from './styles'
import MessageBubble from '../MessageBubble'
import IconText from 'app/components/generic/IconText'
import { checkMark } from '../../constants'
import Avatar from 'app/components/generic/Avatar'

const MessageComponent = ({
  authorName,
  authorType,
  authorIcon,
  timestamp,
  status,
  contentText,
  contentType,
  contentMedia,
}) => {

  const isRightAligned = authorType === 'admin'

  return (
    <styles.Container isRightAligned={isRightAligned}>
      <Avatar
        name={authorName}
        thumbnailUrl={authorIcon}
        avatarImageStyles={styles.avatarImageStyles}
        avatarTextStyles={styles.avatarTextStyles}
      />
      <styles.InnerContainer>
        <MessageBubble
          text={contentText}
          authorType={authorType}
          contentType={contentType}
          contentMedia={contentMedia}
        />
        <IconText
          isRightAligned={isRightAligned}
          url={checkMark}
          text={timestamp}
          isDisplayIcon={(status === 'read' && authorType !== 'admin') ? true : false}
          customStyle={styles.TextStyles}
        />
      </styles.InnerContainer>
    </styles.Container>
  )
}

export default MessageComponent