import dayjs from 'dayjs'
import { Validator } from 'format-utils'
import { isEmpty, isNil } from 'lodash'
import blacklist from 'validator/lib/blacklist'
import isAlpha from 'validator/lib/isAlpha'
import isAlphanumeric from 'validator/lib/isAlphanumeric'
import isDate from 'validator/lib/isDate'
import isDecimal from 'validator/lib/isDecimal'
import isEmail from 'validator/lib/isEmail'
import isFloat from 'validator/lib/isFloat'
import isInt from 'validator/lib/isInt'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isPostalCode from 'validator/lib/isPostalCode'
import isURL from 'validator/lib/isURL'

const ALPHANUMERIC_WITH_WHITESPACE_REGEX = /^[a-zA-Z0-9 ]+$/

export const validator = (isRequired, value, handler) => {
  if (!isRequired && isEmpty(value)) {
    return undefined
  }

  if (typeof handler === 'function') {
    return handler()
  }
}

export const validateEmail =
  ({ isRequired }) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isEmail(value || '')) {
        return 'Please enter valid email'
      }
    })
  }

export const validateMobile =
  ({ isRequired }) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      /**
       * Check only for indian mobile phone number,
       * passing locale for india as second argument
       **/
      if (!isMobilePhone(value || '', 'en-IN')) {
        return 'Please enter valid mobile'
      }
    })
  }

export const validateName =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && !isAlpha(blacklist(value, ' .'))) {
        return 'Please enter valid name'
      }
    })
  }

export const validateAlphanumericName =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isAlphanumeric(value)) {
        return 'Please enter valid name'
      }
    })
  }

export const validateAlphanumericWhitespace =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!ALPHANUMERIC_WITH_WHITESPACE_REGEX.test(value)) {
        return 'Please enter valid name'
      }
    })
  }

export const validateFee =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      if (!(isInt(value) || isFloat(value))) {
        return 'Please enter valid value'
      }
    })
  }

export const validateIntFee =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      if (!isInt(value)) {
        return 'Please enter a whole number'
      }
    })
  }

export const validateRange =
  ({ isRequired, min, max } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      if (
        !(
          (isInt(value) || isFloat(value)) &&
          parseInt(value) >= min &&
          parseInt(value) <= max
        )
      ) {
        if (parseInt(value) < min)
          return `Value greater than or equal to ${min}`
        else if (parseInt(value) > max)
          return `Value less than or equal to ${max}`
        else return 'Please enter valid value'
      }
    })
  }

export const validateCount =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      if (!isNil(value) && !(isInt(value) && value >= 0)) {
        return 'Please enter a valid number'
      }
    })
  }

export const validatePercentage =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      if (!isNil(value) && !(isInt(value) && value >= 0 && value <= 100)) {
        return 'Please enter a valid percentage between 0 and 100'
      }
    })
  }

export const validateDecimal =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isDecimal(value)) {
        return 'Please enter valid value'
      }
    })
  }

export const validatePolicyAcknowledgment =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (value === false) {
        return 'Please acknowledge to proceed'
      }
    })
  }

export const validateNotEmpty =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      if (
        typeof value === 'string' &&
        (isEmpty(value) || value === '<p></p>\n')
      ) {
        return 'Please enter a valid value'
      }
    })
  }

export const validateNotNil =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (isNil(value)) {
        return 'Please enter a valid value'
      }
    })
  }

export const validateNotNilAndBlank =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (isNil(value) || value.toString() === '') {
        return 'Please enter a valid value'
      }
    })
  }

//validating empty or non-gif uploads
export const validateOpenbookThumbnail =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value?.secure_url?.toString()
      }
      if (isEmpty(value) || value.slice(-4) !== '.gif') {
        return 'Please enter a valid value'
      }
    })
  }

export const validateIfsc =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && typeof value !== 'string') {
        value = value.toString()
      }
      if (typeof value === 'string' && !Validator.ifsc(value)) {
        return 'Please enter a valid ifsc'
      }
    })
  }

export const validateURL =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isNil(value) && !isURL(value)) {
        return 'Please enter valid url'
      }
    })
  }

export const validateDate =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isDate(value)) {
        return 'Please enter valid date'
      }
    })
  }

export const validateDateRange =
  ({ isRequired, min, max } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isDate(value)) {
        return 'Please enter valid value'
      }
      if (
        (isDate(min) && dayjs(value).isBefore(dayjs(min))) ||
        (isDate(max) && dayjs(value).isAfter(dayjs(max)))
      ) {
        if (isDate(min) && dayjs(value).isBefore(dayjs(min)))
          return `Date should be greater than or equal to ${min}`
        else if (isDate(max) && dayjs(value).isAfter(dayjs(max)))
          return `Date should be less than or equal to ${max}`
        else return 'Please enter valid value'
      }
    })
  }

export const validatePostalCode =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      /**
       * Check only for indian postal codes,
       * passing locale for india as second argument
       **/
      if (!isPostalCode(value, 'IN')) {
        return 'Please enter valid postal code'
      }
    })
  }

export const validateBio =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      /**
       * Check only for indian postal codes,
       * passing locale for india as second argument
       **/
    })
  }

export const validateHexColor =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      const hexValidatorRGEX = /^#[0-9a-f]{6}/i
      if (!hexValidatorRGEX.test(value)) {
        return 'Please enter a valid color code!'
      }
    })
  }

export const validatePhoneNumber =
  ({ isRequired } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      const phoneNumberValidatorRegex = /^[0-9]{10}$/
      if (!phoneNumberValidatorRegex.test(value)) {
        return 'Please enter a 10 digit number!'
      }
    })
  }

const REFERRAL_CODE_ERROR =
  'Please enter a valid Referral Code or deselect the checkbox to proceed'

export const validateReferralCode =
  ({ isRequired }) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (!isRequired) {
        return undefined
      }
      let isValid = true
      const referralCodeValidatorRegex = /^[A-Z0-9]{6}$/

      isValid = referralCodeValidatorRegex.test(value)
      if (!isValid) {
        return REFERRAL_CODE_ERROR
      }
    })
  }

export const validateBulkImages =
  ({ isRequired, min, max } = {}) =>
  (value) => {
    return validator(isRequired, value, () => {
      if (isEmpty(value)) {
        return 'Please upload images'
      }
      const imagesLength = value?.length
      if (min && imagesLength < min)
        return `Please upload atleast ${min} images`
      else if (max && imagesLength > max)
        return `Please upload max ${max} images only`
      else return null
    })
  }
