/* eslint-disable react/prop-types */
import { Autocomplete, TextField } from '@mui/material'
import _, { get, startCase } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { apiEndpoints } from 'api'
import AssetUploadWidget from 'app/components/AssetUploadWidget'
import BaseEntity from 'app/components/BaseEntity'
import {
  commonAttributes,
  tableColumnDefaultOption,
  uploadPresets,
  yesNoOptions
} from 'app/config'
import constants, { products } from 'app/constants'
import { fetchCourseData, updateClassData } from 'app/store/actions/classEntity'
import { sanitize } from 'app/utils'
import {
  validateCount,
  validateFee,
  validateNotEmpty,
  validateRange
} from 'app/validators'

import { columns } from './config'

const Course = () => {
  const courseData = useSelector((state) => state.classEntityReducer.courseData)
  const batchCount = _.range(1, 6).reduce(
    (o, key) => ({ ...o, [key]: key }),
    {}
  )

  const [selectedSubjects, setSelectedSubjects] = React.useState([])
  const [subjectLevelMapping, setSubjectLevelMapping] = React.useState({})

  React.useEffect(() => {
    const fetchLevelData = async () => {
      const response = await fetch(
        `${apiEndpoints.subject}?uuid=${selectedSubjects?.[0]}`
      )
      if (response?.ok) {
        const levelOptionsJson = await response?.json()
        const levelOptionsArray = levelOptionsJson?.results?.[0]?.subject_levels
        const levelOptionsMapping =
          levelOptionsArray.length > 0
            ? levelOptionsArray.reduce((acc, item) => {
              acc[item.uuid] = item.name
              return acc
            }, {})
            : {}
        return levelOptionsMapping
      }
    }
    if (selectedSubjects.length === 1) {
      fetchLevelData().then((levelOptionsMapping) => {
        setSubjectLevelMapping(levelOptionsMapping)
      })
    }
  }, [selectedSubjects])

  const modifiedColumns = React.useMemo(() => {
    return [
      {
        name: 'slug',
        label: 'Slug',
        filterKey: 'slug',
        options: {
          ...tableColumnDefaultOption.options,
          customBodyRender: (value) => {
            return <span>{value}</span>
          }
        }
      },
      ...columns.slice(0, 1),
      {
        ...commonAttributes.subject,
        label: 'subjects (internal names)',
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return {
              uuid: item.uuid,
              name: item.internal_name
            }
          }
          if (Array.isArray(data)) {
            return data.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
            return [getItemStructure(data)]
          }

          return data.results.map((item) => {
            return getItemStructure(item)
          })
        },
        options: {
          ...commonAttributes.subject.options,
          filterKey: 'internal_names',
          customBodyRender: (value) => (
            <span>
              {Array.isArray(value) && value.map((item) => item.internal_name).join(', ')}
            </span>
          )
        }
      },
      {
        name: 'level',
        label: 'level',
        widget: 'select',
        valueLabelMappings: subjectLevelMapping,
        disabledInFormOnEdit: true,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          customBodyRender: (value) => {
            return <span>{value ? value?.name : 'N/A'}</span>
          }
        },
        conditionalRender: (data) => {
          let subjectsData = get(data, 'subjects')
          setSelectedSubjects(subjectsData)
          return subjectsData.length === 1
        }
      },
      ...columns.slice(1),
      {
        name: 'age',
        label: 'age',
        optional: true,
        widget: 'range',
        min_name: 'min_age',
        max_name: 'max_age',
        min_label: 'min age',
        max_label: 'max age',
        validate: validateRange,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'age',
          customBodyRenderLite: (dataIndex) => {
            const { min_age, max_age } = courseData.data[dataIndex]
            if (min_age && max_age) {
              return (
                <span>
                  {min_age}-{max_age}
                </span>
              )
            }

            return null
          }
        }
      },
      {
        name: 'coins_per_session',
        label: 'Coins per session',
        validate: validateCount,
        options: {
          ...tableColumnDefaultOption.options,
          customBodyRender: (value) => {
            return <span>{value}</span>
          }
        }
      },
      {
        name: 'coins_per_month',
        label: 'Coins per month',
        validate: validateCount,
        options: {
          ...tableColumnDefaultOption.options,
          customBodyRender: (value) => {
            return <span>{value}</span>
          }
        }
      },
      {
        name: 'batches_per_booking',
        label: 'Batches per SKU',
        widget: 'select',
        widgetFilter: true,
        validate: validateNotEmpty,
        valueLabelMappings: batchCount,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'type',
          customBodyRender: (value) => {
            return <div>{value}</div>
          }
        }
      },
      {
        ...commonAttributes.boards,
        label: 'Boards'
      },
      {
        name: 'modules',
        label: 'Modules',
        formDataKey: 'modules',
        widget: 'autocomplete',
        validate: validateNotEmpty,
        optional: true,
        minCharactersToSearch: 0,
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return {
              uuid: item.uuid,
              name: `${item.name} [${item.level}]`
            }
          }
          if (Array.isArray(data)) {
            return data.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
            return [getItemStructure(data)]
          }

          return data.results.map((item) => {
            return getItemStructure(item)
          })
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            return item.uuid
          }
          return data.map((item) => {
            return getItemStructure(item)
          })
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'modules',
          keyValue: 'uuid'
        },
        apiOptions: {
          method: 'get',
          url: apiEndpoints.modules
        },
        multiple: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'modules',
          customBodyRender: (value) => (
            <span>
              {Array.isArray(value) &&
                value.map((item) => `${item.name} [${item.level}]`).join(', ')}
            </span>
          ),
          setCellProps: () => ({
            style: {
              minWidth: '500px',
              maxWidth: '500px',
              whiteSpace: 'pre-wrap'
            }
          })
        }
      },
      {
        name: 'mindsets',
        label: 'Skills',
        formDataKey: 'mindsets',
        widget: 'autocomplete',
        validate: validateNotEmpty,
        optional: true,
        minCharactersToSearch: 0,
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return {
              uuid: item.uuid,
              name: item.name
            }
          }
          if (Array.isArray(data)) {
            return data.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
            return [getItemStructure(data)]
          }

          return data.results.map((item) => {
            return getItemStructure(item)
          })
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            return item.uuid
          }
          return data.map((item) => {
            return getItemStructure(item)
          })
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'mindsets',
          keyValue: 'uuid'
        },
        apiOptions: {
          method: 'get',
          url: apiEndpoints.mindsets
        },
        multiple: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'mindsets',
          customBodyRender: (value) => (
            <span>
              {Array.isArray(value) &&
                value.map((item) => item.name).join(', ')}
            </span>
          ),
          setCellProps: () => ({
            style: {
              minWidth: '500px',
              maxWidth: '500px',
              whiteSpace: 'pre-wrap'
            }
          })
        }
      },
      {
        name: 'details',
        label: 'Course Details',
        widget: 'wysiwyg',
        validate: validateNotEmpty,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'details',
          customBodyRender: (value) => {
            return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
          },
          setCellProps: () => ({
            style: {
              minWidth: '500px',
              maxWidth: '500px',
              whiteSpace: 'pre-wrap'
            }
          })
        }
      },
      {
        name: 'fee',
        label: 'Monthly Fees',
        validate: validateFee,
        ...commonAttributes.amount
      },
      {
        ...commonAttributes.image,
        name: 'hero',
        label: 'Hero Artefact*',
        fileUrlOnly: true,
        validate: validateNotEmpty,
        uploadPreset: uploadPresets[constants.COURSE]
      },
      {
        name: 'is_makeup_allowed',
        label: 'Are makeups allowed?',
        widget: 'select',
        widgetFilter: true,
        validate: validateNotEmpty,
        valueLabelMappings: yesNoOptions,
        ...tableColumnDefaultOption
      },
      {
        name: 'publish_in',
        label: 'Publish to',
        widget: (props) => {
          return (
            <Autocomplete
              style={{
                padding: '10px',
                marginBottom: '5px'
              }}
              multiple
              options={products}
              getOptionLabel={(option) => startCase(option.replace('_', ' '))}
              value={props.formikFieldData || []}
              filterSelectedOptions
              onChange={(_, val) => {
                props.setFieldValue(props.fieldName, val, true)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Publish In" />
              )}
            />
          )
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'publish_in',
          customBodyRender: (value) => (
            <span>{Array.isArray(value) && value.join(', ')}</span>
          ),
          setCellProps: () => ({
            style: {
              minWidth: '300px',
              maxWidth: '300px',
              whiteSpace: 'pre-wrap'
            }
          })
        }
      },
      {
        name: 'trial_class_plan',
        label: 'Trial Class Plan',
        widget: 'wysiwyg',
        optional: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'trial_class_plan',
          customBodyRender: (value) => {
            return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
          },
          setCellProps: () => ({
            style: {
              minWidth: '500px',
              maxWidth: '500px',
              whiteSpace: 'pre-wrap'
            }
          })
        }
      },
      {
        name: 'trial_learning_outcomes',
        label: 'Trial Learning Outcomes',
        widget: 'wysiwyg',
        optional: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'trial_learning_outcomes',
          customBodyRender: (value) => {
            return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
          },
          setCellProps: () => ({
            style: {
              minWidth: '500px',
              maxWidth: '500px',
              whiteSpace: 'pre-wrap'
            }
          })
        }
      },
      {
        name: 'images',
        label: 'Upload image(s)',
        viewOnlyInModal: true,
        modalEdit: true,
        optional: true,
        widget: AssetUploadWidget,
        options: {
          display: false,
          disableFilter: true
        }
      },
      {
        name: 'videos',
        label: 'Upload video(s)',
        viewOnlyInModal: true,
        modalEdit: true,
        optional: true,
        widget: (props) => <AssetUploadWidget assetType="video" {...props} />,
        options: {
          display: false,
          disableFilter: true
        }
      },
      {
        ...commonAttributes.image,
        name: 'thumbnail',
        label: 'Thumbnail for Explore',
        fileUrlOnly: true,
        uploadPreset: uploadPresets[constants.COURSE]
      }
    ]
  }, [courseData, subjectLevelMapping])

  return (
    <BaseEntity
      entity={constants.COURSE}
      label={constants.COURSE_LABEL}
      columns={modifiedColumns}
      data={courseData}
      fetchData={fetchCourseData}
      updateEntityData={updateClassData}
      disableDelete={true}
    />
  )
}

export default Course
