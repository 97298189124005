import EventAvailable from '@material-ui/icons/EventNote';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Event from '@material-ui/icons/Event';
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em;

  width: 22%;
  min-width: 250px;
`

export const ImageContainer = styled.div`
  width: calc(max(3.75em, 60px));
  height: calc(max(3.75em, 60px));
  display: flex;
  border-radius: 50%;
  overflow: hidden;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1em;

  h2 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 1.1em;
  }

  h3 {
    margin: 0;
    margin-left: 8px;
    font-size: 0.9em;
    font-weight: normal;
  }

  div {
    margin-bottom: 5px;
  }
`

export const StudentSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Detail = styled.div`
  display: flex;
  align-items: center;
`

export const AgeIcon = styled(EventAvailable)`
  font-size: 1em;
`

export const StatusIcon = styled(DateRangeIcon)`
  font-size: 1em;
`

export const SessionIcon = styled(Event)`
  font-size: 1em;
`

export const SchoolIcon = styled.img`
  width: 1em;
  height: 1em;
`
