import { isEmpty } from 'lodash'

import constants from 'app/constants'
import {
  interactionDataTransformer,
  ticketDataTransformer
} from 'app/transformer'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, post, patch } from '../request'

const getTargetAudienceType = (userType) => {
  if (userType === constants.STUDENT) return 'students'
  else if (userType === constants.TEACHER) return 'teachers'
  else if (userType === constants.PARENT) return 'parents'
}

const bulkMessagesRequestStructure = (data) => ({
  params: data.params.join(','),
  template: data.template,
  ...(!isEmpty(data.others) ? { others: data.others } : null),
  filters: {
    [getTargetAudienceType(data.userType)]: {
      ...(data.filters || {})
    }
  },
  ...(data.excludedUsers
    ? {
        exclude_users: {
          [getTargetAudienceType(data.userType)]: data.excludedUsers
        }
      }
    : null),
  ...(data.includedUsers
    ? {
        include_users: {
          [getTargetAudienceType(data.userType)]: data.includedUsers
        }
      }
    : null)
})

const templateMessageRequestStructure = (data) => ({
  template_name: data?.template_name,
  params: data?.params
})

const fetchWhatsAppTemplates = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.thirdparty}/whatsapp/templates`, queries)

  try {
    const response = await get(requestUrl)

    return response.data.items
      .filter((item) => item.status === 'APPROVED')
      .map((item) => ({
        value: item.id,
        name: item.name,
        components: item.components
      }))
  } catch (error) {
    throw error
  }
}

const fetchBulkMessages = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.thirdparty}/whatsapp/messages/bulk`,
        queries
      )

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    throw error
  }
}

const getBulkMessage = async (id) => {
  const requestUrl = `${apiEndpoints.thirdparty}/whatsapp/messages/bulk/${id}`

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    throw error
  }
}

const sendBulkMessages = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.thirdparty}/whatsapp/messages/bulk`,
      bulkMessagesRequestStructure(data)
    )

    return response.data
  } catch (error) {
    throw error
  }
}

const sendTemplateMessage = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.thirdparty}/whatsapp/templates/send`,
      templateMessageRequestStructure(data)
    )

    return response.data
  } catch (error) {
    throw error
  }
}

const createWhatsAppTemplate = async (data) => {
  try {
    const response = await post(`${apiEndpoints.thirdparty}/whatsapp`, {
      template_name: data.template_name,
      destination_number: data.destination_number,
      message_string: data.message_string,
      parameters: data.parameters
    })

    return response.data
  } catch (error) {
    throw error
  }
}

const fetchReportCard = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.ohClasses}/report_card/dashboard/`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: response.data.results.map((item) => ({
        ...item,
        created: item.created
      }))
    }
  } catch (error) {
    throw error
  }
}

const createReportCard = async (data) => {
  try {
    // Create an object of formData
    const formData = new FormData()
    // Update the formData object
    formData.append('file', data, data.name)
    const response = await post(
      `${apiEndpoints.ohClasses}/report_card/generate/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

const fetchInteractionHistory = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.interactions}`, queries)
  try {
    const response = await get(requestUrl)
    return interactionDataTransformer(response?.data)
  } catch (error) {
    throw error
  }
}
const updateInteractionHistory = async (data) => {
  const {
    interaction_date,
    connected_status,
    parent,
    interaction_mode = null,
    interaction_summary = null,
    interaction_topics = []
  } = data

  const payload = {
    interaction_date,
    connected_status,
    parent,
    interaction_mode,
    interaction_summary,
    interaction_topics
  }
  try {
    const response = await post(`${apiEndpoints.interactions}/`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

const fetchInteractionTopicOptions = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'CRM_INTERACTION_TOPICS'
  })
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log(error, 'error in fetching deduction reasons')
    throw error
  }
}

const fetchTickets = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.tickets}`, queries)
  try {
    const response = await get(requestUrl)
    return ticketDataTransformer(response?.data)
  } catch (error) {
    throw error
  }
}

const updateTicket = async (data) => {
  const {
    user,
    status,
    type,
    mode,
    description,
    title,
    uuid,
    resolution_summary,
    follow_up_date
  } = data

  let payload = mode
    ? {
        user,
        status,
        mode,
        type,
        description,
        title,
        follow_up_date: follow_up_date || null
      }
    : {}
  payload = resolution_summary
    ? {
        ...payload,
        user,
        status,
        resolution_summary,
        follow_up_date: follow_up_date || null
      }
    : { ...payload, user, status, follow_up_date: follow_up_date || null }

  try {
    const response = await patch(`${apiEndpoints.tickets}/${uuid}/`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

const createTicket = async ({
  user,
  type,
  mode,
  description,
  title,
  status,
  follow_up_date
}) => {
  const payload = {
    user,
    type,
    mode,
    title,
    description,
    status,
    follow_up_date: follow_up_date || null
  }
  try {
    const response = await post(`${apiEndpoints.tickets}/`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

const fetchTicketStatusOptions = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'CRM_TICKET_STATUSES'
  })
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log(error, 'error in fetching deduction reasons')
    throw error
  }
}

const fetchTicketTypeOptions = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'CRM_TICKET_TYPES'
  })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log(error, 'error in fetching deduction reasons')
    throw error
  }
}

export {
  fetchWhatsAppTemplates,
  fetchBulkMessages,
  createWhatsAppTemplate,
  fetchReportCard,
  createReportCard,
  sendBulkMessages,
  getBulkMessage,
  sendTemplateMessage,
  fetchInteractionHistory,
  updateInteractionHistory,
  fetchInteractionTopicOptions,
  fetchTickets,
  updateTicket,
  createTicket,
  fetchTicketStatusOptions,
  fetchTicketTypeOptions
}
