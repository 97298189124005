/* eslint-disable react/prop-types */
import React from 'react'

import Loading from 'app/components/generic/Loading'
import { enrolmentTypes } from 'app/config'
import constants from 'app/constants'

import { StudentItem } from './components/StudentItem'
import * as Styles from './styles'

import { Title } from '../../styles'
import dayjs from 'dayjs'

const StudentsList = ({ studentsData }) => {
  const { isLoading, data } = studentsData
  const regularActiveStudentsData = data.filter(
    (item) =>
      (item.status === constants.ACTIVE ||
        item.status === constants.UPCOMING) &&
      item.type === 'membership'
  )

  // const regularActiveStudentsData = activeStudentsData.filter((value, index, self) => self.findIndex(el => el.student.user_id === value.student.user_id) === index);

  const makeUpStudentsData = data.filter(
    (item) =>
      (item.status === constants.ACTIVE ||
        item.status === constants.UPCOMING) &&
      item.type === 'make_up_session'
  )

  const currentDate = dayjs().format('YYYY-MM-DD')
  const showStudentDetailsBatchType = ['program', 'infinite', 'free_trial']
  const programStudentsData = data.filter(
    (item) =>
      (item.status === constants.ACTIVE
        || item.status === constants.UPCOMING
        || (item.status === constants.PAUSED && item.start_date <= currentDate && currentDate <= item.end_date))
      && showStudentDetailsBatchType.includes(item.type)
  )

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {regularActiveStudentsData.length ? (
            <Title>Regular Session Students</Title>
          ) : null}
          <Styles.ListContainer>
            {regularActiveStudentsData?.map((student) => (
              <StudentItem key={student.uuid} student={student} />
            ))}
          </Styles.ListContainer>
          {makeUpStudentsData.length ? (
            <Title>Make-Up Session Students</Title>
          ) : null}
          <Styles.ListContainer>
            {makeUpStudentsData?.map((student) => (
              <StudentItem key={student.uuid} student={student} />
            ))}
          </Styles.ListContainer>

          {programStudentsData.length ? <Title>Students List</Title> : null}
          <Styles.ListContainer>
            {programStudentsData?.map((student) => (
              <StudentItem key={student.uuid} student={student} />
            ))}
          </Styles.ListContainer>
        </>
      )}
    </>
  )
}

export { StudentsList }
