import { Typography, FormControl } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Dayjs from 'dayjs'
import { debounce, filter, get, isEmpty, isNil, set } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { generateOtp } from 'app/common'
import BaseForm from 'app/components/BaseForm'
import { AutoComplete } from 'app/components/generic/FormFields'
import History from 'app/components/generic/history/history'
import LoadingProgress from 'app/components/generic/Loading'
import { Loading } from 'app/components/generic/LottieComponents'
import Slider from 'app/components/generic/Slider/slider'
import Table, { CustomToolbar, EditAction } from 'app/components/generic/Table'
import {
  readOnlyTableDefaultOption,
  serverSideOptions,
  tableDefaultOption
} from 'app/config'
import constants from 'app/constants'
import {
  getLoggedInUserName,
  eventEmitter,
  getNetworkResponseError,
  makeRequestUrl
} from 'app/helpers'

import { get as getRequest } from '../../../request'

const ActivityLogIcon = require('assets/images/activity_log.svg')
const ChatIcon = require('assets/images/chat.png')
const ThreeDotsIcon = require('assets/images/menu.png')
const OtpIcon = require('assets/images/otp.png')

const errorInitialState = {
  isError: false,
  errorMessage: '',
  fieldErrors: {}
}

const useStyles = makeStyles((theme) => ({
  headerInput: {
    width: '100%',
    minWidth: 150
  },
  headerInputDisabled: {
    borderColor: 'transparent !important'
  },
  tableHeadCell: {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: theme.palette.background.default
  },
  tableHeadCellFixed: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.default
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'lowercase'
  },
  loading: {
    marginLeft: theme.spacing(2)
  },
  actionCell: {
    position: 'sticky',
    right: '0',
    width: '1%',
    minWidth: 50
  },
  actionColumn: {
    display: 'flex',
    alignItems: 'center'
  },
  threeDots: { cursor: 'pointer', paddingRight: '10px' },
  container: {
    position: 'relative'
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 2
  }
}))

const handleFormData = (newFormData, columns, elements) => {
  columns.forEach((column) => {
    if (!column.disabledInForm) {
      if (column.widget === 'range') {
        set(newFormData, column.min_name, get(elements, column.min_name))
        set(newFormData, column.max_name, get(elements, column.max_name))
      } else {
        const element = get(elements, column.formDataKey || column.name)
        if (element !== null && element !== undefined) {
          if (element instanceof HTMLElement) {
            if (element.type !== 'file') {
              set(newFormData, column.formDataKey || column.name, element.value)
            }
          } else {
            if (!isEmpty(column.resultStructure)) {
              if (column.widget === 'autocomplete') {
                set(
                  newFormData,
                  column.resultStructure.keyName,
                  typeof element === 'string'
                    ? element
                    : column.getFormDataValue(element)
                )
              } else {
                set(
                  newFormData,
                  column.resultStructure.keyName,
                  typeof element === 'string'
                    ? element
                    : Array.isArray(element) && !isEmpty(element)
                      ? column.multiple
                        ? element
                        : element[0]
                      : get(element, column.resultStructure.keyValue)
                )
              }
            } else {
              set(newFormData, column.formDataKey || column.name, element)
            }
          }
        }
      }
    }
  })
}

const BaseTable = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [currentEditingData, setCurrentEditingData] = React.useState({})
  const [formData, setFormData] = React.useState({})
  const [error, setError] = React.useState(errorInitialState)
  const [currentPage, setCurrentPage] = React.useState(0) // using page zero based index
  const [filterQueries, setFilterQueries] = React.useState({})
  const [refetching, setRefetching] = React.useState(false)
  const [viewMode, setviewMode] = React.useState(false)
  const [rowsSelected, setRowsSelected] = React.useState([])
  const classes = useStyles()

  const {
    data,
    label,
    fetchData,
    handleDelete,
    onSaveClick,
    columns,
    readOnly,
    disableDelete,
    CustomToolbarElement,
    createDependency,
    disableEditButton,
    startFetchingOnMount,
    query,
    selectableRows,
    setIsCreateClicked
  } = props

  const urlParams = new URLSearchParams(window.location.search)
  const queryParams = Object.fromEntries(urlParams.entries())

  const debouncedFetchData = React.useCallback(
    debounce(({ ...rest }) => {
      if (Object.keys(rest.queries).includes('journey_mentor')) {
        localStorage.setItem('journey_mentor', rest.queries['journey_mentor'])
      }
      localStorage.setItem('filterQueries', JSON.stringify({ ...rest }))
      fetchData({ ...rest })
    }, 1000),
    []
  )

  React.useEffect(() => {
    localStorage.setItem(constants.PAGE_NO, 0)
  }, [])

  React.useEffect(() => {
    if (startFetchingOnMount) {
      const params = new URLSearchParams(window.location.search)
      const queryParams = Object.fromEntries(params.entries())

      const queries = {
        ...query
      }

      if (params.get('editMode') === '1') {
        const id = params.get('uuid')

        if (!isEmpty(id)) {
          queries.uuid = id
        }
      }

      Object.keys(queries).length ? fetchData({ queries }) : fetchData()

      if (!params.get('editMode') && !params.get('openModalForm')) {
        console.log('queryparams', queryParams)
        Object.keys(queryParams).length
          ? fetchData({ queries: queryParams, isFiltering: true })
          : fetchData()
      }
    }
  }, [startFetchingOnMount])

  React.useEffect(() => {
    if (refetching) {
      debouncedFetchData({ queries: filterQueries, isFiltering: true })
    }
  }, [filterQueries, debouncedFetchData, refetching])

  const handleModalClose = React.useCallback(() => {
    setError(errorInitialState)
    setIsModalOpen(false)
  }, [])

  const handleResetFilterQueries = React.useCallback(() => {
    setFilterQueries({})
  }, [])

  const handleModalFormSaveError = React.useCallback((error) => {
    setError(getNetworkResponseError(error))
  }, [])

  const onRefetchingStop = React.useCallback(() => {
    if (refetching) {
      setRefetching(false)
    }
  }, [refetching])

  React.useEffect(() => {
    eventEmitter.addListener(constants.REFETCHING_STOP, onRefetchingStop)

    return () => {
      eventEmitter.removeListener(constants.REFETCHING_STOP, onRefetchingStop)
    }
  }, [onRefetchingStop])

  React.useEffect(() => {
    eventEmitter.addListener(constants.CLOSE_MODAL_FORM, handleModalClose)

    return () => {
      eventEmitter.removeListener(constants.CLOSE_MODAL_FORM, handleModalClose)
    }
  }, [handleModalClose])

  React.useEffect(() => {
    eventEmitter.addListener(
      constants.REMOVE_ALL_FILTERS,
      handleResetFilterQueries
    )

    return () => {
      eventEmitter.removeListener(
        constants.REMOVE_ALL_FILTERS,
        handleResetFilterQueries
      )
    }
  }, [handleResetFilterQueries])

  React.useEffect(() => {
    const handler = () => {
      setRowsSelected([])
    }

    eventEmitter.addListener(constants.REMOVE_SELECTED_ROWS, handler)

    return () => {
      eventEmitter.removeListener(constants.REMOVE_SELECTED_ROWS, handler)
    }
  }, [])

  React.useEffect(() => {
    eventEmitter.addListener(
      constants.MODAL_FORM_SAVE_ERROR,
      handleModalFormSaveError
    )

    return () => {
      eventEmitter.removeListener(
        constants.MODAL_FORM_SAVE_ERROR,
        handleModalFormSaveError
      )
    }
  }, [handleModalFormSaveError])

  const handleCreateClick = React.useCallback(() => {
    const newFormData = {
      isNewEntry: true
    }

    const initialValues = columns.reduce((accumulator, current) => {
      if (!current.disabledInForm) {
        if (
          typeof current.conditionalRender === 'function' &&
          current.conditionalRender(accumulator) !== true
        ) {
          /**
           * no need to add this key value in form object
           * as anyway its not going to render in form
           * so, returning previous object state
           */
          return accumulator
        }

        const newObject = set(
          { ...accumulator },
          current.formDataKey || current.name,
          current.widget === 'autocomplete' && current.multiple
            ? []
            : typeof current.defaultValue === 'function'
              ? current.defaultValue(accumulator)
              : ''
        )
        return newObject
      }

      return accumulator
    }, {})
    setviewMode(false)
    handleFormData(newFormData, columns, initialValues)
    setCurrentEditingData({}) // resetting previous edit data
    setFormData(newFormData)
    setIsModalOpen(true)
    if (typeof setIsCreateClicked === 'function') setIsCreateClicked(true)
  }, [columns])

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('openModalForm') === '1') {
      handleCreateClick()
    }
  }, [])

  const onHeaderTextChange = React.useCallback((event) => {
    const { name, value } = event.target
    if ((!isEmpty(value) || !isNil(value)) && value) {
      setFilterQueries((state) => {
        return {
          ...state,
          [name]: value
        }
      })
    } else {
      setFilterQueries((state) => {
        const { [name]: _name, ...newState } = state

        return newState
      })
    }

    setRefetching(true)
  }, [])

  const onHeaderWidgetChange = React.useCallback(
    (name, headerWidgetInputType, value, filterKey) => {
      switch (headerWidgetInputType) {
        case 'daterange':
          const { startDate, endDate } = value
          const startDateQueryName = `${filterKey ? filterKey : name}_gte`
          const endDateQueryName = `${filterKey ? filterKey : name}_lte`

          if (startDate) {
            setFilterQueries((state) => {
              return {
                ...state,
                [startDateQueryName]: Dayjs(startDate).format('YYYY-MM-DD')
              }
            })
          } else {
            setFilterQueries((state) => {
              const { [startDateQueryName]: _startDateQueryName, ...newState } =
                state

              return newState
            })
          }

          if (endDate) {
            setFilterQueries((state) => {
              return {
                ...state,
                [endDateQueryName]: Dayjs(endDate).format('YYYY-MM-DD')
              }
            })
          } else {
            setFilterQueries((state) => {
              const { [endDateQueryName]: _endDateQueryName, ...newState } =
                state

              return newState
            })
          }

          setRefetching(true)

        // case 'monthrange':
        //   const { monthStartDate } = value

        //   console.log('value', value, monthStartDate)
        //   const monthStartDateQueryName = `${name}`
        //   if (monthStartDate) {
        //     console.log('here:', monthStartDate, monthStartDateQueryName)
        //     setFilterQueries((state) => {
        //       return {
        //         ...state,
        //         [monthStartDateQueryName]:
        //           Dayjs(monthStartDate).format('YYYY-MM-DD')
        //       }
        //     })
        //   } else {
        //     console.log('also here:', monthStartDate, monthStartDateQueryName)
        //     setFilterQueries((state) => {
        //       const {
        //         [monthStartDateQueryName]: _monthStartDateQueryName,
        //         ...newState
        //       } = state

        //       return newState
        //     })
        //   }
        //   setRefetching(true)

        default:
          return null
      }
    },
    []
  )

  const handleAutocompleteChange = (event, value, isValueKey) => {
    console.log(event, value, isValueKey)
    let obj = {
      target: {
        name: event || '',
        value: isValueKey ? value.uuid : value?.name || value || null
      }
    }
    onHeaderTextChange(obj)
  }

  const handleSelectChange = (event, valueLabelMappings, isValueKey) => {
    try {
      const mappedValue = valueLabelMappings[event.target.value]
      let obj = {
        target: {
          name: event.target.name,
          value: isValueKey ? event.target.value : mappedValue
        }
      }
      onHeaderTextChange(obj)
    } catch (error) {
      console.error('Error while fetching Options of widget type select', error)
    }
  }

  const fetchOptions = async (url, getStructuredValues) => {
    try {
      if (url) {
        const response = await getRequest(url)
        const data = response?.data
        const results = getStructuredValues(data)
        return results
      }
      return null
    } catch (error) {
      console.error('Error while fetching Options:', error, url)
      throw new Error('Failed to fetch data from server')
    }
  }

  const customHeadRender = React.useCallback(
    (columnMeta, columnOption = {}) => {
      const {
        name,
        label,
        index,
        filterKey,
        disableFilter,
        fixedColumn,
        headerWidget,
        headerWidgetInputType
      } = columnMeta
      /*handle default case where config files are different*/
      const {
        widget = '',
        widgetFilter = false,
        getStructuredValues = {},
        apiOptions: { url = '' } = {},
        valueLabelMappings = {},
        isValueKey = false
      } = columnOption

      const HeaderWidgetComponent =
        headerWidget && headerWidgetInputType ? headerWidget : null
      const fetchDataAutocomplete = () =>
        !isEmpty(valueLabelMappings)
          ? valueLabelMappings
          : url
          ? fetchOptions(url, getStructuredValues)
          : null

      return (
        <th
          key={index}
          className={
            fixedColumn ? classes.tableHeadCellFixed : classes.tableHeadCell
          }
        >
          {HeaderWidgetComponent ? (
            <HeaderWidgetComponent
              name={name}
              filterKey={filterKey}
              onChange={(name, headerWidgetInputType, value) => {
                onHeaderWidgetChange(
                  name,
                  headerWidgetInputType,
                  value,
                  filterKey
                ) // Added 'filterKey' as an additional argument
              }}
              placeholder={label.toLowerCase()}
              headerWidgetInputType={headerWidgetInputType}
            />
          ) : !isEmpty(label) ? (
            widgetFilter == true && widget == 'autocomplete' ? (
              <FormControl
                key={filterKey || name}
                variant="outlined"
                className={classes.headerInput}
              >
                <AutoComplete
                  async={false}
                  name={filterKey || name}
                  label={label.toLowerCase()}
                  multiple={false}
                  mappedOptions={valueLabelMappings}
                  minCharactersToSearch={0}
                  fetchValues={fetchDataAutocomplete}
                  onChange={(event, value) =>
                    handleAutocompleteChange(event, value, isValueKey)
                  }
                  defaultValue={queryParams[filterKey] || undefined}
                />
              </FormControl>
            ) : widgetFilter == true && widget == 'select' ? (
              <FormControl
                key={filterKey || name}
                variant="outlined"
                className={classes.headerInput}
              >
                <InputLabel>{label.toLowerCase()}</InputLabel>
                <Select
                  name={filterKey || name}
                  label={label.toLowerCase()}
                  onChange={(event) => {
                    handleSelectChange(event, valueLabelMappings, isValueKey)
                  }}
                  renderValue={(selectedItem) => {
                    return valueLabelMappings[selectedItem]
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {Object.entries(valueLabelMappings).map(([value, label]) => (
                    <MenuItem
                      key={value}
                      value={queryParams[filterKey] || value}
                    >
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                name={filterKey || name}
                label={label.toLowerCase()}
                variant="outlined"
                className={classes.headerInput}
                InputProps={{
                  classes: {
                    notchedOutline: disableFilter
                      ? classes.headerInputDisabled
                      : null
                  }
                }}
                onChange={onHeaderTextChange}
                disabled={disableFilter}
                value={
                  queryParams[filterKey] ||
                  filterQueries[filterKey || name] ||
                  ''
                }
              />
            )
          ) : null}
        </th>
      )
    },
    [classes, filterQueries, onHeaderTextChange, onHeaderWidgetChange]
  )

  const modifiedColumns = React.useMemo(() => {
    const handleEditClick = (dataIndex, viewState) => {
      setviewMode(viewState)
      const newFormData = {}
      setCurrentEditingData(data.data[dataIndex])
      handleFormData(newFormData, columns, data.data[dataIndex])
      setFormData(newFormData)
      setIsModalOpen(true)
      if (typeof setIsCreateClicked === 'function') setIsCreateClicked(false)
    }

    const _modifiedColumns = [
      ...columns.map((column) => {
        let columnOption = {
          ...column
        }

        if (
          Object.prototype.hasOwnProperty.call(column, 'valueLabelMappings') &&
          !(
            Object.prototype.hasOwnProperty.call(
              column.options,
              'customBodyRenderLite'
            ) ||
            Object.prototype.hasOwnProperty.call(
              column.options,
              'customBodyRender'
            )
          ) &&
          !isEmpty(column.valueLabelMappings)
        ) {
          const { valueLabelMappings, name } = column
          columnOption = {
            ...column,
            options: {
              ...column.options,
              customBodyRenderLite: (dataIndex) => {
                return (
                  <span>
                    {valueLabelMappings[get(data.data[dataIndex], name)] ||
                      get(data.data[dataIndex], name) ||
                      ''}
                  </span>
                )
              }
            }
          }
        } else if (
          Object.prototype.hasOwnProperty.call(column, 'contentType') &&
          column.contentType === 'link'
        ) {
          const { name } = column
          columnOption = {
            ...column,
            options: {
              ...column.options,
              customBodyRenderLite: (dataIndex) => {
                return (
                  <a
                    href={data.data[dataIndex][name]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.data[dataIndex][name]}
                  </a>
                )
              }
            }
          }
        }
        return {
          ...columnOption,
          options: {
            ...columnOption.options,
            customHeadRender: (columnMeta) =>
              customHeadRender(columnMeta, columnOption)
          }
        }
      })
    ]

    if (!readOnly && !disableEditButton) {
      _modifiedColumns.push({
        name: '',
        label: '',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({
            className: classes.actionCell
          }),
          customBodyRenderLite: (dataIndex) => {
            const { uuid, user } = data.data[dataIndex]
            return (
              <div className={classes.actionColumn}>
                <EditAction
                  onEditClick={() => handleEditClick(dataIndex, false)}
                  id={uuid || user?.uuid}
                />
              </div>
            )
          },
          customHeadRender
        }
      })
    }
    return _modifiedColumns
  }, [data, columns, customHeadRender, readOnly, classes, disableEditButton])

  const handleOnSearchChange = React.useCallback(
    debounce((search) => {
      try {
        if (!isEmpty(search)) {
          fetchData({ queries: { search }, isFiltering: true })
        } else {
          fetchData({ isFiltering: true })
        }
      } catch (error) {
        console.log(error)
      }
    }, 500),
    []
  )

  const handleOnChangePage = React.useCallback(
    (page) => {
      try {
        if (page > currentPage) {
          if (data.next) {
            const urlSplit = data.next.split(':')
            // fetch next result
            fetchData({
              url: `https:${urlSplit[1]}`,
              isPaginating: true,
              isFiltering: true
            })
            setCurrentPage(page)
            localStorage.setItem(constants.PAGE_NO, page)
          }
        } else if (page < currentPage) {
          if (data.previous) {
            const urlSplit = data.previous.split(':')
            // fetch previous result
            fetchData({
              url: `https:${urlSplit[1]}`,
              isPaginating: true,
              isFiltering: true
            })
            setCurrentPage(page)
            localStorage.setItem(constants.PAGE_NO, page)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [currentPage, data, fetchData, filterQueries]
  )

  const modifiedTableDefaultOption = React.useMemo(() => {
    let modifiedOptions = {}
    let isCreateEnabled = !createDependency && !readOnly

    if (readOnly || disableDelete) {
      modifiedOptions = {
        ...readOnlyTableDefaultOption
      }
    } else {
      modifiedOptions = {
        ...tableDefaultOption
      }
    }

    modifiedOptions = {
      ...modifiedOptions,
      ...(!disableDelete ? { onRowsDelete: handleDelete } : null),
      ...(selectableRows ? { selectableRows } : null),
      rowsSelected,
      onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
        setRowsSelected(rowsSelected)
      },
      ...(isCreateEnabled
        ? {
          customToolbar: () => {
            return <CustomToolbar onCreateClick={handleCreateClick} />
          }
        }
        : null)
    }

    if (CustomToolbarElement) {
      modifiedOptions = {
        ...modifiedOptions,
        customToolbar: () => {
          return (
            <CustomToolbar
              additionalElement={CustomToolbarElement}
              {...(isCreateEnabled
                ? { onCreateClick: handleCreateClick }
                : null)}
            />
          )
        }
      }
    }

    return {
      ...modifiedOptions,
      ...serverSideOptions,
      count: data.count,
      rowsPerPage: 25,
      page: parseInt(localStorage.getItem(constants.PAGE_NO)),
      onChangePage: handleOnChangePage,
      onSearchChange: handleOnSearchChange
    }
  }, [
    handleDelete,
    data,
    disableDelete,
    handleOnChangePage,
    handleOnSearchChange,
    readOnly,
    CustomToolbarElement,
    createDependency,
    handleCreateClick,
    rowsSelected
  ])

  React.useEffect(() => {
    // handling to open modal form to edit information of that user
    const params = new URLSearchParams(window.location.search)

    if (params.get('editMode') === '1' && !isEmpty(data)) {
      const id = params.get('uuid')

      if (!isEmpty(id)) {
        const editButtonElement = document.getElementById(
          `edit-button-id-${id}`
        )

        if (editButtonElement) {
          editButtonElement.click()
        }
      }
    }
  }, [data])

  const titleComponent = React.useMemo(() => {
    return (
      <div className={classes.tableHeader}>
        <Typography variant="h6">
          {typeof label === 'function' ? label() : label}
        </Typography>

        {refetching ? (
          <CircularProgress className={classes.loading} size={30} />
        ) : null}
      </div>
    )
  }, [refetching, label, classes])

  return (
    <>
      {data.isLoading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          {data.isPaginating ? (
            <div className={classes.loadingContainer}>
              <LoadingProgress />
            </div>
          ) : null}
          <Table
            title={titleComponent}
            data={data.data}
            columns={modifiedColumns}
            options={modifiedTableDefaultOption}
          />
          <BaseForm
            viewMode={viewMode}
            title={titleComponent}
            formData={formData}
            setFormData={setFormData}
            onSaveClick={onSaveClick}
            isModalOpen={isModalOpen}
            currentEditingData={currentEditingData}
            error={error}
            columns={columns}
            handleFormData={handleFormData}
            handleModalClose={handleModalClose}
            modalCss={props.modalCss}
          />
        </div>
      )}
    </>
  )
}

BaseTable.defaultProps = {
  disableDelete: false,
  disableEditButton: false,
  readOnly: false,
  createDependency: false,
  startFetchingOnMount: true,
  query: {},
  setIsCreateClicked: null
}

BaseTable.propTypes = {
  data: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.func.isRequired
  ]),
  fetchData: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSaveClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
  disableDelete: PropTypes.bool.isRequired,
  CustomToolbarElement: PropTypes.object,
  disableEditButton: PropTypes.bool.isRequired,
  createDependency: PropTypes.bool.isRequired, // this is the case if new data creation is happening on click on another table
  startFetchingOnMount: PropTypes.bool,
  query: PropTypes.object,
  selectableRows: PropTypes.string,
  setIsCreateClicked: PropTypes.func
}

export default BaseTable
